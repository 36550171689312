.logo {
  display: flex;
  align-items: center;
  float: left;
  height: 100%;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

ul {
  border-bottom: none !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 100000s ease-in-out 0s;
  transition-property: background-color, color;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0000002a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2C59BB;
}

#action-column tbody {
  cursor: pointer;
}